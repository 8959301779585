.dropdownContainer {
    width: 140px;
    height: 20px;
    margin: 15px;
}

.Dropdown-root {
    font-size: 12px;
}

.Dropdown-control  {
    background-color: #282c34;
    color: white;
}

.Dropdown-option  {
    background-color: #282c34;
    color: white;
}

.Dropdown-title {
    background-color: #282c34 ;
    color: whitesmoke !important;
    font-size: 11px !important;
    font-weight: 900 !important;
    margin-top: 1px;
    padding: 8px 10px 0px 10px !important;
}

.CustomDropdown {
    width: 120px;
}