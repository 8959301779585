.dropdownContainer {
    width: 100px;
    height: 20px;
    margin: 15px;
    cursor: pointer;
}

.Dropdown-root {
    font-size: 12px;
}

.Dropdown-control  {
    background-color: #282c34;
    color: white;
}

.Dropdown-option  {
    background-color: #282c34;
    color: white;
}