.signInUp {
    display: flex;

}

.title {
    color: #DBE213;
    font-weight: bold;
    font-size: 22px;
    cursor: pointer;
}

.flex-box {
    display:flex;
    justify-content:space-between;
    margin-bottom: 5px;
}

button {
    background-color: #c4c4c9;
    color: #282c34;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    padding: 10px;
    margin-right: 10px;
}

.buyBeer {
    background-color: #c4c4c9;
    font-size: 18px;
    border-radius: 16px;
    color: black;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
    padding: 10px;
}

  