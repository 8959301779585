.signupTitle {
    color: white;
    font-size: 20pt;
    margin-bottom: 30px;
}


.login {
    background: #282c34;
    width: 300px;
    display: block;
    overflow: auto;
    padding: 50px;
    margin-right: auto; 
    margin-left: auto;
    margin-top: 50px;
    clear: both;
}

.login .formLabel {
    font-size: 16px;
    width: 100%;
    margin-top: 50px;
    color: white;
    clear: both;
    display: table-cell;
}

.errorText {
    font-size: 12px;
    color: red;
}

.login input {
    width: 100%;
    height: 18px;
    margin-top: 5px;
    margin-bottom: 20px;
    clear: both;
    display: table-cell;
}

.login button {
    margin: 5px;
    background: white;
    width: 100px;
    clear: both;
    display: table-cell;
    cursor: pointer;
}


.createAccount {
    width: 100%;
    clear: both;
    display: table-cell;
}


.createAccountMsg {
    display: inline-block; 
    margin-top: 40px;
    margin-bottom: 10px;
    font-size: 14px;
    text-decoration: underline;
    color: white;
    text-underline-position: below;
    cursor: pointer;
    width: 100%;
}


.forgotPassword label {
    text-underline-position: below;
    cursor: pointer;
    width: 100%;
    font-size: 14px;
    color: white;
    text-decoration: underline;
}

.modal {
    width: 500px;
    display: block;
    overflow: auto;
    margin-right: auto; 
    margin-left: auto;
    clear: both;
}

.page {
    height: 863px;
    width: 1345px;
}

.verify {
    background: #282c34;
    width: 600px;
    display: block;
    overflow: auto;
    padding: 50px;
    margin-right: auto; 
    margin-left: auto;
    margin-top: 50px;
    clear: both;
}

.centerText {
    display: flex;
    justify-content: center;
    align-items: center;
}


