.tableFixHead          { overflow-x: auto; overflow-y: auto; max-height: 1000px; margin-bottom: 100px; /*min-width: max-content;*/}
.tableFixHead thead th { position: sticky; top: 0; }
th     { background:#282c34; }

.tableFixHead th {
    box-shadow: 0 2px 0px 0px gray;
}

.positionDropdown {
    flex: 0 0 auto;
}

.scoringDropdown {
    flex: 0 0 auto;
}

.teamDropdown {
    flex: 0 0 auto;
}

.compareSwitch {
    flex: 0 0 auto;
    margin: 15px;
    padding: 3px;
    padding-top: 4px;
}


.controls {
    text-align: left;
    padding-left: 20px;
    display: flex;
}

.spinner {
    height: 750px;
    padding-top: 100px;
}